import { insightsRepository } from "../../../repository/insights.repository";

const getNextQuery = (params, list, has_more) => {
    const lastId = list.length > 0 ? list[list.length - 1].id : 0;
    const nextQuery = { ...params };
    nextQuery.starting_after = lastId;
    nextQuery.has_more = has_more;
    return nextQuery;
};

const mergeList = (previousList, newList) => {
    const list = previousList ? [...previousList, ...newList] : newList;
    let idSet = new Set();

    const uniqueList = list.filter((item) => {
        let isUnique = false;
        if (!idSet.has(item.id)) {
            idSet.add(item.id);
            isUnique = true;
        }
        return isUnique;
    });
    return uniqueList;
};

const dependentGivenComments = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentGivenComments(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const dependentReceivedComments = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentReceivedComments(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const dependentMostCommentedCreation = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentMostCommentedCreation(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const dependentGivenBinkies = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentGivenBinkies(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const dependentReceivedBinkies = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentReceivedBinkies(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const dependentMostBinkiedCreation = (params, previousList) => {
    return new Promise((resolve, reject) => {
        insightsRepository
            .dependentMostBinkiedCreation(params)
            .then((result) => {
                const commentList = mergeList(previousList, result.data);
                const query = getNextQuery(params, commentList, result.has_more);
                const data = {
                    commentList,
                    query,
                };
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function addKidMood(data) {
    return new Promise((resolve, reject) => {
      insightsRepository
        .addKidMood(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  function getKidMood(data) {
    return new Promise((resolve, reject) => {
      insightsRepository
        .getKidMood(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getFeelingsList(feeling) {
    return new Promise((resolve, reject) => {
      insightsRepository
        .getFeelingsList(feeling)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export const insightsViewModel = {
    dependentGivenComments,
    dependentReceivedComments,
    dependentMostCommentedCreation,
    dependentGivenBinkies,
    dependentReceivedBinkies,
    dependentMostBinkiedCreation,
    addKidMood,
    getKidMood,
    getFeelingsList
}