import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import MultiBg from '../../../assets/multi/Kid_background@2x.jpg';
import CalmImg from '../../../assets/multi/Calm.jpg';
import ConfidentImg from '../../../assets/multi/Confident.jpg';
import HappyImg from '../../../assets/multi/Happy.jpg';
import Kid1Img from '../../../assets/multi/Kid1.jpg';
import Kid2Img from '../../../assets/multi/Kid2.jpg';
import Kid3Img from '../../../assets/multi/Kid3.jpg';
import ParentImg from '../../../assets/multi/Parent.jpg';

const MultiLogin = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#141414',
        backgroundImage: `url(${MultiBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center',
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 1200 }}>
        <Typography
          variant="h2"
          sx={{
            mb: 3,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          }}
        >
          Select a profile
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          {[
            {
              name: 'Max',
              img: Kid1Img,
              icon: 'V',
              iconColor: '#FF69B4',
              popOutImg: HappyImg,
            },
            {
              name: 'Oliver',
              img: Kid2Img,
              icon: 'E',
              iconColor: '#8A2BE2',
              popOutImg: CalmImg,
            },
            {
              name: 'Sophie',
              img: Kid3Img,
              icon: 'S',
              iconColor: '#2ECC71',
              popOutImg: ConfidentImg,
            },
          ].map((profile, index) => (
            <Grid
              item
              key={index}
              sx={{
                flex: '0 1 180px',
                cursor: 'pointer',
                position: 'relative',
                padding: '10px',
                borderRadius: '10px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                },
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  '&:hover .popOutImg': {
                    opacity: 1,
                    visibility: 'visible',
                    transform: 'translateY(-20px) scale(1)',
                  },
                }}
              >
                {/* Profile Image */}
                <Box
                  component="img"
                  src={profile.img}
                  alt={profile.name}
                  sx={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                    },
                  }}
                />
                {/* Pop-out Image (shown above the profile image when hovered) */}
                <Box
                  className="popOutImg"
                  sx={{
                    position: 'absolute',
                    top: '-9px', // Positioned above the profile image
                    left: '90%', // To the right side of the profile image
                    width: '50px',
                    height: '50px',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                    opacity: 0,
                    visibility: 'hidden',
                    transition: 'all 0.3s ease',
                    zIndex: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={profile.popOutImg}
                    alt="Pop-out"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Box>
              {/* Profile Icon */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '40px',
                  right: '20px',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: profile.iconColor,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                  transition: 'all 0.3s ease',
                }}
              >
                {profile.icon}
              </Box>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '1.2em',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
                }}
              >
                {profile.name}
              </Typography>
            </Grid>
          ))}
          <Box
            sx={{
              width: '1px',
              height: '150px',
              backgroundColor: 'rgba(255,255,255,0.3)',
              margin: '0 20px',
              alignSelf: 'center',
            }}
          />
          <Grid
            item
            sx={{
              flex: '0 1 180px',
              cursor: 'pointer',
              position: 'relative',
              padding: '10px',
              borderRadius: '10px',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
          >
            <Box
              component="img"
              src={ParentImg}
              alt="Guardian"
              sx={{
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                objectFit: 'cover',
                border: '2px solid #fff',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(255,255,255,0.5)',
                },
              }}
            />
            <Typography
              sx={{
                mt: 1,
                fontSize: '1.2em',
                textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
              }}
            >
              Guardian
            </Typography>
            <Typography
              sx={{
                fontSize: '1em',
                mt: 0.5,
                color: '#ccc',
                textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
              }}
            >
              John
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MultiLogin;
