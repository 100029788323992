import { isPayByCamp } from "./utils"

export const getKidSubscription = (kid) => {
    if (kid.profile.is_activated) {
        if (kid.subscription.subscription_id) {
            if (kid.subscription.nickname === 'Villagers' || kid.subscription.nickname === 'Branches') {
                return 'Villagers'
            }
            if (
                kid.subscription.nickname === 'Roots' ||
                kid.subscription.nickname === 'Seedlings V2' ||
                kid.subscription.nickname === 'Seedlings'
            ) {
                return 'Seedlings'
            }
        } else {
            if (kid?.profile?.tribe_request_status === "accept" && isPayByCamp(kid?.school_details?.pay_by)) {
                return 'Seedlings'
            } else {
                return 'Emeralites'
            }
        }
    } else {
        return 'Unverified'
    }
}