import React, { useEffect } from 'react';
import { Image, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../../assets/logo/logo-53x53.svg';
import '../../header/css/camp.header.scss';
import { useHistory } from 'react-router-dom';
import TaletreeHeader from '../../header/TaletreeHeader';
import {
  MY_SCHOOL_ROUTE,
  SCHOOL_KIDS_ROUTE,
  PARTNER_CHALLENGES,
  MY_PARTNER_ROUTE,
  HOME_FEED,
  SCHOOL_CHALLENGES,
  SCHOOL_ADD_CHALLENGES,
  PARTNER_ADD_CHALLENGES,
  PARTNER_EDIT_TREEHOUSE,
  PARTNER_ADD_TREEHOUSE,
  CAMP_ACCOUNT,
} from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import ProfileMenu from '../../header/ProfileMenu';
import { changeBodyClassName } from '../../../utils/utils';
import { bg } from '../../../constants/component.constants';

const PartnerHeader = () => {
  const history = useHistory();
  const { logout, school, partner } = useAuth();

  useEffect(() => {
    changeBodyClassName([bg.CHALLENGES])
    localStorage.setItem('mode', 'light')
  }, [])

  return (
    <TaletreeHeader className="camp-header kid partner-nav">
      <div className="navbar-brand-wrapper">
        <LinkContainer to={HOME_FEED}>
          <Navbar.Brand>
            <Image src={logo} className="logo" />
          </Navbar.Brand>
        </LinkContainer>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="">
        <Nav className=" align-items-md-center navbar-sub navbar-custom ">
          <LinkContainer to={partner ? MY_PARTNER_ROUTE : MY_SCHOOL_ROUTE}>
            <Nav.Link
              className={
                history.location.pathname === MY_PARTNER_ROUTE ||
                history.location.pathname === MY_SCHOOL_ROUTE ||
                history.location.pathname === PARTNER_EDIT_TREEHOUSE ||
                history.location.pathname === PARTNER_ADD_TREEHOUSE
                  ? 'px-3 camp-header-link active'
                  : 'px-3 camp-header-link'
              }
            >
              <p>Dashboard</p>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={school ? SCHOOL_CHALLENGES : PARTNER_CHALLENGES}>
            <Nav.Link
              className={
                history.location.pathname === SCHOOL_ADD_CHALLENGES ||
                history.location.pathname === PARTNER_ADD_CHALLENGES ||
                history.location.pathname.slice(0, 15) === '/camp-challenge' ||
                history.location.pathname.slice(0, 18) === '/partner_challenge'
                  ? 'px-3 camp-header-link active'
                  : 'px-3 camp-header-link'
              }
            >
              <p>
                {partner
                  ? partner.user_type === 'camp master'
                    ? 'Expert Profile'
                    : 'My Treehouse'
                  : 'Common room'}
              </p>
            </Nav.Link>
          </LinkContainer>
          {school ? (
            <LinkContainer to={SCHOOL_KIDS_ROUTE} id="captain-step-three">
              <Nav.Link className="px-3 camp-header-link">
                <p>My camp</p>
              </Nav.Link>
            </LinkContainer>
          ) : (
            ''
          )}
          {school && (
            <LinkContainer className="mob-menu" to={CAMP_ACCOUNT}>
              <Nav.Link
                className={
                  history.location.pathname === CAMP_ACCOUNT
                    ? 'px-3 camp-header-link active'
                    : 'px-3 camp-header-link'
                }
              >
                <span className="mob-menu" style={{ paddingLeft: 0 }}>
                  Account details
                </span>
              </Nav.Link>
            </LinkContainer>
          )}
          <Nav.Link
            className="px-4 camp-header-link logout"
            onClick={() => logout()}
          >
            Logout
          </Nav.Link>
          <div className="profile-menu-header-container">
            <ProfileMenu />
          </div>
        </Nav>
      </Navbar.Collapse>
    </TaletreeHeader>
  );
};

export default PartnerHeader;
