import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ERROR_REQUIRED_PASSWORD, kidLoginErrors } from '../../../constants/error.constants';
import {
  FORGOT_PASSWORD_DEPENDENT_ROUTE,
  GUARDIAN_LOGIN_ROUTE,
  LOGIN_ROUTE,
} from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import './scss/kid.login.scss';
import EmptyHeader from '../../header/EmptyHeader';
import ResponseErrorNotification from '../../api/ResponseErrorNotification';
import ValidHttpStatusError from '../../api/api.error.valid';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import { useForm } from 'react-hook-form';
import { useResponseError } from '../../api/response.error';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import FormPasswordInput from '../../common/FormPasswordInput';
import LoadButton from '../../common/LoadButton';
import gateRight from '../../../assets/images/gate-right.svg';
import gateLeft from '../../../assets/images/gate-left.svg';
import gateLock from '../../../assets/images/gate-lock.svg';
import loginUni from '../../../assets/images/login-uni.svg';
import diamondsLeft from '../../../assets/images/diamonds-left.png';
import diamondsRight from '../../../assets/images/diamonds-right.png';
import Typed from 'typed.js';
import { customEvents } from '../../../analytics/customEvents';
import OptimizeSignup from '../onboarding/signup_popups/OptimizeSignup';
import CloudTreeA from '../../../assets/images/cloud1.png';
import CloudTreeB from '../../../assets/images/cloud2.png';
import CloudTreeC from '../../../assets/images/cloud3.png';
import CloudTreeD from '../../../assets/images/cloud4.png';
import CloudTreeE from '../../../assets/images/cloud5.png';
import CloudTreeF from '../../../assets/images/cloud6.png';
import CloudTreeG from '../../../assets/images/cloud7.png';
import CloudTreeH from '../../../assets/images/cloud8.png';
import CloudTreeI from '../../../assets/images/cloud9.png';
import '../map/Taletree.css';

const KidLogin = () => {
  const { dependentLogin } = useAuth();
  const { register, handleSubmit, setError, errors, watch } = useForm({
    mode: 'onBlur',
  });
  const history = useHistory();
  const location = useLocation();
  const defaultEmail = useMemo(() => location?.state?.verified_email);
  const { addError, removeError } = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const [formLockedLockClass, setFormLockedLockClass] = useState('form-locked');
  const [gateLockStyle, setGateLockStyle] = useState('block');
  const [uniStyle, setUniStyle] = useState('block');
  const [gateLeftToggleClass, setGateLeftToggleClass] = useState('');
  const [gateRightToggleClass, setGateRightToggleClass] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cloudAnimationDisplay, setCloudAnimationDisplay] = useState('none');
  const text = 'Welcome! Click to open the gate to go to the Emerald Forest.';
  const typedRef = useRef();

  const onSubmit = (data) => {
    // removeError;
    setIsLoading(true);
    setCloudAnimationDisplay('flex');
    dependentLogin(data).catch((error) => {
      if (error instanceof ValidHttpStatusError) {
        displayFormErrorsFromApi(error, setError);
      } else {
        addError(error.message);
      }
      setIsLoading(false);
      setCloudAnimationDisplay('none');
    });
  };

  const handleLockClick = () => {
    setFormLockedLockClass('');
    setGateLockStyle('none');
    setUniStyle('none');
    setGateLeftToggleClass('gate-left-opened');
    setGateRightToggleClass('gate-right-opened');
  };

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [text],
      typeSpeed: 50,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [text]);

  useEffect(() => {
    let analyticsData = {
      category: 'Kid Login',
      action: 'kid login',
      label: 'kid login',
    };
    customEvents(analyticsData);
  }, []);

  useEffect(() => {
    if (watch('username')) {
      let analyticsData = {
        category: 'Kid Login',
        action: 'nickname',
        label: 'nickname',
      };
      customEvents(analyticsData);
    }
  }, [watch('username')]);

  useEffect(() => {
    if (watch('password')) {
      let analyticsData = {
        category: 'Kid Login',
        action: 'password',
        label: 'password',
      };
      customEvents(analyticsData);
    }
  }, [watch('password')]);

  const onNotAKidClick = () => {
    let analyticsData = {
      category: 'Kid Login',
      action: 'are you guardian login here',
      label: 'are you guardian login here',
    };
    customEvents(analyticsData);
    history.push(LOGIN_ROUTE);
  };

  return (
    <div className="onboarding-wrapper kid-login-container login-container-wrapper kids-main-login">
      <EmptyHeader />
      <div className="kid-login-bg not-kid">
        <div className="not-a-kid gred-btn" onClick={onNotAKidClick}>
          Not a Kid?
        </div>
        <Container className="onboarding-container login-container" fluid="md">
          <div
            class="clouds-container"
            style={{ display: cloudAnimationDisplay }}
          >
            <div class="cloudS cloud1">
              <img src={CloudTreeA} alt="Cloud" />
            </div>
            <div class="cloudS cloud2">
              <img src={CloudTreeB} alt="Cloud" />
            </div>
            <div class="cloudS cloud3">
              <img src={CloudTreeC} alt="Cloud" />
            </div>
            <div class="cloudS cloud4">
              <img src={CloudTreeD} alt="Cloud" />
            </div>
            <div class="cloudS cloud5">
              <img src={CloudTreeE} alt="Cloud" />
            </div>
            <div class="cloudS cloud6">
              <img src={CloudTreeF} alt="Cloud" />
            </div>
            <div class="cloudS cloud7">
              <img src={CloudTreeG} alt="Cloud" />
            </div>
            <div class="cloudS cloud8">
              <img src={CloudTreeH} alt="Cloud" />
            </div>
            <div class="cloudS cloud9">
              <img src={CloudTreeI} alt="Cloud" />
            </div>
          </div>
          <div className="login-wrapper-with-img">
            <div
              className={`login-form-wrapper kid-login ${formLockedLockClass}`}
            >
              <div className="title text-center">Kid Login</div>
              <div className="subTitle text-center">
                Log In to your account and start to upload your creations!
              </div>
              <ResponseErrorNotification />
              <Row className="input-wrapper mt-2">
                <Col>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                      <Form.Group className="container-fluid">
                        <FormLabel>
                          Nickname
                          <span>*</span>
                        </FormLabel>
                        {defaultEmail && (
                          <FormLabel className="float-right text-theme">
                            E-Mail validated{' '}
                            <span className="support-lable-check">
                              <GoCheck />
                            </span>
                          </FormLabel>
                        )}
                        <FormInput
                          defaultValue={defaultEmail}
                          name="username"
                          placeholder="Type nickname"
                          data-testid="username"
                          ref={register({
                            required: kidLoginErrors.username.required,
                          })}
                          className="email-color"
                        />
                        <ErrorLabel
                          errors={errors}
                          name="username"
                          as="label"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="container-fluid mb-0">
                        <FormLabel>
                          Password<span>*</span>
                        </FormLabel>
                        <FormPasswordInput
                          name="password"
                          placeholder="Type password"
                          data-testid="password"
                          ref={register({ required: ERROR_REQUIRED_PASSWORD })}
                        />
                        <ErrorLabel
                          errors={errors}
                          name="password"
                          as="label"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="forgot-password">
                        <Link to={FORGOT_PASSWORD_DEPENDENT_ROUTE}>
                          Forgot password?
                        </Link>
                      </div>
                    </Form.Row>
                    <Form.Row>
                      <div className="remember-me">
                        <input
                          defaultChecked
                          type="checkbox"
                          name="remember_me"
                          ref={register()}
                        />
                        &nbsp; Remember me
                      </div>
                    </Form.Row>
                    <Form.Row className="login-submit">
                      <Form.Group className="container-fluid mb-0">
                        <LoadButton
                          text="Login"
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          className="login-btn p-2 gred-btn"
                          testid="submit"
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
            </div>
            {/* <div className="Emerald-forest-badge">
              <img src={emraldForest} alt='emerald forest' />
            </div> */}
            <div className="login-gate-wrapper" onClick={handleLockClick}>
              <div className={`gate-left ${gateLeftToggleClass}`}>
                <img src={gateLeft} alt="login gate left" />
              </div>
              <div className={`gate-right ${gateRightToggleClass}`}>
                <img src={gateRight} alt="login gate right" />
              </div>
              <div className={'gate-lock'} style={{ display: gateLockStyle }}>
                <img src={gateLock} alt="Gate Lock" />
              </div>
            </div>
            <div className="diamonds-img">
              {/* <img src={gateDiamonds} alt='Diamonds' /> */}
              <div className="diamonds-left">
                <img src={diamondsLeft} alt="Diamonds" />
              </div>
              <div className="diamonds-right">
                <img src={diamondsRight} alt="Diamonds" />
              </div>
            </div>
            <div className={'login-uni'} style={{ display: uniStyle }}>
              <img src={loginUni} alt="Uni" />
              <div className="uni-msg">
                <span ref={typedRef}></span>
              </div>
            </div>
          </div>
          <Row className="are-you">
            Don’t have an account?&nbsp;
            <span className="underline-span" onClick={() => setShowModal(true)}>
              Sign up here
            </span>
          </Row>
        </Container>
      </div>
      <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
      <div className="type1"></div>
      <div className="lensflare"></div>
    </div>
  );
};

export default KidLogin;
