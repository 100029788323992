export const HAPPY_THOUGHTS_1 = "My family brought me joy";
export const HAPPY_THOUGHTS_2 = "I had fun with my friends";
export const HAPPY_THOUGHTS_3 = "My family loves me very much";
export const HAPPY_THOUGHTS_4 = "I helped someone today";
export const EXCITED_THOUGHTS_1 = "I made a new friend";
export const EXCITED_THOUGHTS_2 = "I'm improving little by little";
export const EXCITED_THOUGHTS_3 = "I learned a new way to paint";
export const EXCITED_THOUGHTS_4 = "I liked my challenges";
export const EXCITED_THOUGHTS_5 = "My friend loved my drawing";
export const CALM_THOUGHTS_1 = "I listened to soothing music";
export const CALM_THOUGHTS_2 = "I can see progress in my challenge";
export const CALM_THOUGHTS_3 = "I played with my pet";
export const CALM_THOUGHTS_4 = "I believe in myself";
export const CALM_THOUGHTS_5 = "I sang my favorite song";
export const ANNOYED_THOUGHTS_1 = "It’s too noisy to read";
export const ANNOYED_THOUGHTS_2 = "I'm running out of ideas";
export const ANNOYED_THOUGHTS_3 = "My colors are all mixed up";
export const ANNOYED_THOUGHTS_4 = "I can't find my favorite book";
export const ANNOYED_THOUGHTS_5 = "I accidentally smudged my drawing";
export const CONFIDENT_THOUGHTS_1 = "I studied hard";
export const CONFIDENT_THOUGHTS_2 = "I learned a new song";
export const CONFIDENT_THOUGHTS_3 = "I spoke up in class and shared my idea";
export const CONFIDENT_THOUGHTS_4 = "I tried a new activity and did really well";
export const CONFIDENT_THOUGHTS_5 = "I drew a picture and everyone said it was great";
export const SCARED_THOUGHTS_1 = "I had a bad dream last night";
export const SCARED_THOUGHTS_2 = "I have to speak in front of the whole class";
export const SCARED_THOUGHTS_3 = "I'm not sure if my drawing will be good enough";
export const SCARED_THOUGHTS_4 = "I'm afraid people won't like my artwork.";
export const SCARED_THOUGHTS_5 = "I saw a big spider";
export const ANGRY_THOUGHTS_1 = "My crayon keeps breaking";
export const ANGRY_THOUGHTS_2 = "I ran out of paper and can't finish my project";
export const ANGRY_THOUGHTS_3 = "My challenge was harder than I expected";
export const ANGRY_THOUGHTS_4 = "I made a mistake in my project";
export const ANGRY_THOUGHTS_5 = "I feel stuck and frustrated";
export const WORRIED_THOUGHTS_1 = "My challenge is really hard";
export const WORRIED_THOUGHTS_2 = "I don't know if I can do it";
export const WORRIED_THOUGHTS_3 = "I'm not sure what to do next";
export const WORRIED_THOUGHTS_4 = "I'm running out of time";
export const WORRIED_THOUGHTS_5 = "I'm afraid of making mistakes";
