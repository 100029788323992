import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();

function dependentGivenComments(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/comments/given?${query}`);
}

function dependentReceivedComments(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/comments/received?${query}`);
}

function dependentMostCommentedCreation(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/comments/most-commented-creation?${query}`);
}

function dependentGivenBinkies(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/binkies/given?${query}`);
}

function dependentReceivedBinkies(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/binkies/received?${query}`);
}

function dependentMostBinkiedCreation(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/binkies/most-binkies-creation?${query}`);
}

function addKidMood(data) {
  return fetchUtils.post(`${BASE_URL}/dependent-mood/add`, data);
}

function getKidMood(data) {
  return fetchUtils.get(`${BASE_URL}/dependent-mood?dependent_id=${data?.dependent_id}&year=${data?.year}&month=${data.month}&mood=${data?.mood}`)
}

function getFeelingsList(feeling) {
  return fetchUtils.newGet(
    `${BASE_URL}/emotion-checkin/reason/list?emotion=${feeling}`
  );
}

export const insightsRepository = {
  dependentGivenComments,
  dependentReceivedComments,
  dependentMostCommentedCreation,
  dependentGivenBinkies,
  dependentReceivedBinkies,
  dependentMostBinkiedCreation,
  addKidMood,
  getKidMood,
  getFeelingsList
};